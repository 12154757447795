/* Care.css */
/* DL 9/23/2k22 */

/* Packages, dependencies, and shared libraries */
@import "../../../globals.css";

/**************/
/* Plant Care */
/**************/

/* Care Container is a child of Content Section class
   Defined in Body.css */
#careContainer {
  text-align: center;
  /*padding: 10px 20px 10px 20px;*/
  font-size: 12px;
  border-width: 0.5px;
  border-radius: 10px;
  border-color: green;
  background-color: var(--globalAccentColor2);

  margin: 10px 0px 10px 0px;
}

#careHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  border-radius: 10px;
  padding: 10px 20px 10px 20px;

  font-family: var(--globalFont);
  font-weight: bold;
}

.careHeaderWhite {
  color: var(--globalFontColor);
  background-color: var(--globalAccentColor2);
}

.careHeaderColor {
  color: white; /*var(--globalFontColor);*/
  background-color: green;
}

#careHeader:hover {
  cursor: pointer;
  /*opacity: 70%;*/
}

.careInstructions {
  background-color: white;
  margin: 10px;
  padding: 2px;
  border-radius: 10px;
}

.careTitle {
  display: flex;
  vertical-align: middle;
  font-family: var(--globalFont);
  font-size: 16px;
}

.careBlurbSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.careBlurb {
  width: 80%;
  font-family: var(--globalFont);
  font-size: 12px;
  padding: 0px 12px 0px 20px;
}

#arrow {
  display: flex;

  border-top-width: 4px;
  border-right-width: 4px;
  border-bottom-width: 0;
  border-left-width: 0;
  border-style: solid;
  width: 15px;
  height: 15px;
  /*margin-right: 5px;
  padding-top: 3px;

  margin-bottom: 5px;*/
}

.arrowDown {
  transform: translateY(0px) rotate(135deg);
  vertical-align: top;
  border-color: white; /*var(--globalFontColor);*/

  /*margin-top: 2px;*/
}

.arrowUp {
  /*margin-top: 10px;*/
  vertical-align: middle;
  transform: translateY(8px) rotate(-45deg);
  border-color: var(--globalFontColor);
}

.careItems {
  display: inline;
  text-align: left;
}

div.careInstruction {
  text-align: center;
  opacity: 100%;
  display: flex;
  vertical-align: top;
  margin: 5px 5px 0px 5px;
}

div.careIcon {
  /*margin: 10px 5px 10px 5px;*/
  opacity: 100%;

  text-align: center;
}

p.careInstructionText {
  margin: 10px 10px 10px 10px;
  overflow: wrap;
  overflow-wrap: break-word;
  display: flex;
  align-items: center;
}

.careLink {
  margin: 10px 10px 10px 10px;
  overflow: wrap;
  overflow-wrap: break-word;
  cursor: pointer;
  display: flex;
  align-items: center;
}

div.careLink:hover {
  opacity: 70%;
}

div.careBubbleContainer {
  margin: 0px 5px 2px 0;
  font-family: var(--globalFont);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /*padding-left: 15px;
  padding-right: 0px;*/
  padding-bottom: 12px;
  background-color: var(--globalAccentColor2);
  border-radius: 15px;
}

div.careBubble {
  margin: 0.5vw 2.5vw 0.5vw 2.5vw;
  width: 75px;
  height: 75px;
  border-radius: 10px;
  border-style: solid;
  border-width: 0px;
  border-color: var(--globalFontColor);

  color: black;
  /*background-color: var(--globalAccentColor2);*/
  display: inline-block;
  vertical-align: text-bottom;
  text-align: center;

  padding-top: 9px;
  /*padding-bottom: 3px;
  padding-left: 2px;
  padding-right: 2px;*/
}

.careBubbleTextContainer {
  height: 30px;
  padding-top: 5px;
}
.careBubbleText {
  font-size: 11px;
  font-weight: 500;
}

.careDetailsButton {
  justify-content: center;
  text-align: center;
  background-color: forestgreen;
  opacity: 100%;
  color: white;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 0px;
  font-family: var(--globalFont);
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.careDetailsButton:hover {
  opacity: 70%;
}
