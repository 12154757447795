@import "../globals.css";

/****************/
/* Plant Sprite */
/****************/

.spriteArea {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.spriteBackground {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: min(300px, 90%);
}

div.spriteContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: squish 4s ease-in forwards 1s infinite;
}

div.plantImageContainer {
  position: relative;
}

@keyframes squish {
  0% {
    transform: scaleY(1);
  }
  5% {
    transform: scaleY(0.94) translateY(3%);
  }
  10% {
    transform: scaleY(1);
  }
  15% {
    transform: scaleY(0.94) translateY(3%);
  }
  20% {
    transform: scaleY(1);
  }
}

.potImageContainer {
  position: relative;
  height: fit-content;
  width: 75%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
}

img.plantBody {
  position: relative;
  z-index: 5;
  max-width: 100%;
  max-height: 100%;
}

img.pot {
  width: 75%;
  position: relative;
  z-index: 1;
  bottom: 0;
}

img.eyes {
  position: absolute;
  width: 75%;
  z-index: 3;
  bottom: 0;
  left: 0;
  left: 50%;
  transform: translateX(-50%);
}

img.mouth {
  width: 75%;
  position: absolute;
  z-index: 4;
  bottom: 0;
  left: 0;
  left: 50%;
  transform: translateX(-50%);
}

img.prop {
  width: 75%;
  z-index: 5;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
