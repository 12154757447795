/* PlantEditor.css */
/* TK 7/25/2k22 */

@import "../../globals.css";

.title {
  font-family: var(--globalFont);
  color: var(--globalFontColor);
  font-size: 36px;
  text-align: center;
  margin: 2vh auto;
}

.textbox {
  height: 5vh;
  margin: 0 auto;
  border-radius: 3px;
}

.plantIDTextbox {
  width: 30vw;
  display: block;
}

.getPlantButton {
  margin: 1vh auto;
  display: block;
  width: 15vw;
  height: 5vh;
  background-color: var(--globalAccentColor2);
  border-radius: 3px;
  cursor: pointer;
}

.plantFieldContainer {
  margin-bottom: 2vh;
}

.plantFieldContainer:last-child {
  margin-bottom: 0;
}

.plantTextField {
  width: 25vw;
  height: 4vh;
  display: block;
}

.plantTextFieldLabel {
  display: block;
  font-size: 16px;
}

.plantTextFieldHeader {
  display: block;
  font-size: 20px;
}

.plantPromptTextbox {
  height: 10vh;
  width: 40vw;
  display: block;
  margin-bottom: 1vh;
}

.plantResponseTextbox {
  height: 20vh;
  width: 40vw;
  word-wrap: normal;
  display: block;
}

.plantEditor {
  background-color: var(--globalAccentColor2);
  width: 75%;
  font-family: var(--globalFont);
}

/* Tabs */

.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  width: 40vw;
}

.tab button {
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  background-color: var(--globalFontColor);
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}
