/* globals.css */
/* TK 10/18/2k22 */

/***************************************/
/* Global CSS Declarations and Classes */
/***************************************/

:root {
  --globalBackgroundColor: rgb(252, 230, 179); /*#fce6b3;*/
  --globalFont: Arial; /*Comic Sans MS;*/
  --globalFontColor: #000000; /* rgb(92, 64, 51); /*black;*/ /*#5c4033;*/
  --globalAccentColor: #ffffff; /*#ffffff;*/
  --globalAccentColor2: #fcfcf0; /*#ffefcc;*/
  --bodyBackgroundColor: rgb(255, 240, 207); /*#fff0cf;*/
  background-color: var(--globalBackgroundColor);
}

/* Element fade in animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Element fade out animation */
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Line for the footer */
.line {
  display: block;
  border-bottom: 3px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-color: black;
  border-style: solid;
  width: 300px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20px;
}
