/* MiscPages.css */

@import "../globals.css";

/****************/
/* Loading Page */
/****************/

.loadingPage {
  height: 100%;
}

.loadingContainer {
  position: fixed;
  width: 600px;
  height: 600px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loadingPotImage {
  left: 50%;
  top: 200px;
  transform: translateX(-50%);
  width: 50px;
  position: absolute;
  z-index: 2;
}

.loadingTopImage {
  left: 50%;
  top: 120px;
  transform: translateX(-50%);
  width: 160px;
  position: absolute;
  z-index: 2;
}

/**************/
/* Error Page */
/**************/

.errorImage {
  width: 500px;
  display: block;
  margin: auto;
}

.errorText {
  font-family: var(--globalFont);
  color: red;
  text-align: center;
  font-size: 30px;
  margin-top: 10px;
}

.randomPlantButton {
  justify-content: center;
  text-align: center;
  background-color: forestgreen;
  opacity: 100%;
  color: white;
  width: fit-content;
  height: 40px;
  border-radius: 5px;
  border: 0px;
  font-family: var(--globalFont);
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

/******************/
/* Page Not Found */
/******************/

.contactUs {
  font-family: var(--globalFont);
  margin-top: 20px;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-bottom: 20px;
  text-decoration: underline;
}

.contactUs:hover {
  opacity: 70%;
  cursor: pointer;
}
