.grid-container {
  display: grid;
  justify-items: center;
  /*grid-template-columns: auto auto auto auto;*/
  /*background-color: #2196f3;*/
  /*padding: 16px 12px 20px 12px;
  height: 492px;
  width: 380px;*/
}
.grid-item {
  /*background-color: rgba(255, 255, 255, 0.8);
  border: 0px solid rgba(0, 0, 0, 0.8);
  padding: 16.75px 13.65px 11.75px 13.65px;*/
  font-size: 30px;
  /*text-align: left;*/
  /*width: 70px;*/
}

.nameAndCodes {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding-left: 8px;
  padding-bottom: 3px;

  position: relative;
  z-index: 100;
}

.nameText {
  font-family: "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  /*padding-top: 2px;*/
  color: green;
  white-space: nowrap;
  width: 50px;
  z-index: 100;
  /*textOverflow: "ellipsis",*/
  font-weight: 600;
  text-shadow: -1.5px 1.5px 0 #fff, 1.5px 1.5px 0 #fff, 1.5px -1.5px 0 #fff,
    -1.5px -1.5px 0 #fff;
}

.helloMyNameIsText {
  font-family: "Pacifico", cursive;
  font-size: 8px;
}

.speciesText {
  font-size: 10px;
  margin-top: -6px;
  color: green;
  white-space: nowrap;
  width: 50px;
  z-index: 100;
  /*textOverflow: "ellipsis",*/
  font-weight: 600;
  text-shadow: -1.5px 1.5px 0 #fff, 1.5px 1.5px 0 #fff, 1.5px -1.5px 0 #fff,
    -1.5px -1.5px 0 #fff;
}

.qrCode {
  height: 50px;
  width: 50px;
  margin-bottom: 1.5px;
}

.qrCode > img {
  height: 50px;
}

.sprite {
  object-fit: contain;
  height: 100px;
  /*padding-right: 5px;*/

  /*width: 80px;
  /*width: 40px;*/
  /*border-radius: 10px;*/
  /*background-color: #006400;*/
}

.qrCodeFace {
  bottom: 48.5px;
  left: -26px;
  height: 18px;
  width: 18px;
  border-radius: 10px;
  background-color: #006400;
}

.qrText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: -42px;
  font-size: 4px;
  padding-top: 7px;
  padding-left: 5px;
  padding-right: 5px;
  /*width: 60px;*/
  /*height: 0px;*/
  text-align: center;
}

#left {
  text-align: left;
}
#right {
  text-align: right;
}
