/* Login.css */
/* TK 7/10/2k22 */

@import "../../globals.css";

.loginText,
.loginEntry,
.loginButton {
  font-family: var(--globalFont);
  color: var(--globalFontColor);
}

.loginText {
  padding-top: 25vh;
  text-align: center;
  font-size: 32px;
}

input.loginEntry {
  width: 30vw;
  height: 5vh;
  display: block;
  margin: 3vh auto;
  border-radius: 3px;
}

input.loginButton {
  margin: 1vh auto;
  display: block;
  width: 15vw;
  height: 5vh;
  background-color: var(--globalAccentColor2);
  border-radius: 3px;
  cursor: pointer;
}

input.loginButton:hover {
  opacity: 70%;
}

.loginErrorMessage {
  margin-top: 5vh;
  text-align: center;
  font-family: var(--globalFont);
  color: red;
}
