/* Home.css */
/* TK 7/13/2k22 */

@import "../../globals.css";

/* Sizing for tiles */

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  text-align: center;
}
.item {
  width: 25vw;
  height: 35vh;
  margin: 2vh auto;
  background-color: var(--globalAccentColor2);
  cursor: pointer;
  font-family: var(--globalFont);
  color: var(--globalFontColor);
  justify-content: center;
}

.item:hover {
  opacity: 70%;
}

/* Home Page Header */

.pageHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}

.goHome {
  margin-top: 10px;
  margin-left: 2vw;
  text-align: left;
  font-family: var(--globalFont);
  cursor: pointer;
}

.goHome:hover {
  opacity: 70%;
}

.signOut {
  margin: 1vh auto;
  display: block;
  width: 7vw;
  height: 5vh;
  background-color: var(--globalAccentColor2);
  border-radius: 3px;
  cursor: pointer;
  margin-top: 10px;
  margin-right: 2vw;
  align-self: flex-end;
}
