/* plantBioPage.css */
/* TK 10/18/2k22 */

@import "../globals.css";

/******************/
/* Plant Bio Page */
/******************/

.plantBioPage {
  background-color: var(--globalBackgroundColor);
  padding-top: 15;
  margin: auto;

  /*border-style: solid;
  border-radius: 20px;
  border-width: 15px;*/

  padding-left: max(5px, calc((100vw - 600px) / 2));
  padding-right: max(5px, calc((100vw - 600px) / 2));
}

/*************************/
/* Plant Bio Page Footer */
/*************************/

.footer {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(5px + 2vmin);
  color: #5c4033;
  padding: 20px;
  border: 0px;
}

/**************/
/* Contact Us */
/**************/

.contactUs {
  font-family: var(--globalFont);
  margin-top: 20px;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-bottom: 20px;
  text-decoration: underline;
}

.contactUs:hover {
  opacity: 70%;
  cursor: pointer;
}
