/* App.css */
@import "./globals.css";

/* The App component encases everything */
.app {
  background-color: var(--globalBackgroundColor);
}

:root {
  background-color: green;
}

.contentSection {
  background-color: var(--globalAccentColor);
  border-color: var(--globalFontColor);
  border-width: 0px;
  border-style: solid;
  border-radius: 5px;
}

a:link {
  color: #5c4033;
}

a:visited {
  color: #5c4033;
}
