/************************/
/* Bio Section Classes */
/************************/

#bio {
  display: flex;
  flex-direction: column;
  justify-content: top;
  font-size: calc(10px + 2vmin);
  text-align: left;
  padding: 0px 0px 0px 0px;
  border-width: 0px;
  margin-top: 12px;
}

.bioTitleSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /*margin-left: auto;
  margin-right: auto;*/
  font-size: calc(15px + 2vmin);
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 0px;
  text-align: left;
  color: var(--globalFontColor);

  font-family: var(--globalFont);
}

div.bioPair {
  font-family: var(--globalFont);
  color: var(--globalFontColor);
  margin-top: 8px;
  margin-bottom: 18px;
  padding-bottom: 0;
  padding: 0px 20px 0px 20px;
}

.prompt {
  font-size: calc(8px + 1vmin);
  margin-bottom: 5px;
}

.response {
  margin: 0 0 0 0px;
  font-size: calc(14px + 1vmin);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: rgb(0, 0, 0);
}

/* Bio Bubbles */

div.bioBubbleContainer {
  margin: 10px 0 10px 0;
  font-family: var(--globalFont);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-left: 15px;
  padding-right: 15px;
}

div.bioBubble {
  margin: 1vw 1.5vw 1vw 1.5vw;
  width: 90px;
  height: 90px;
  border-radius: 10px;
  border-style: solid;
  border-width: 0px;
  border-color: var(--globalFontColor);
  color: dimgray;
  /*background-color: var(--globalAccentColor2);*/
  display: flex;
  flex-direction: column;
  vertical-align: top;
  text-align: center;
}

div.bioBubbleText {
  padding: 7px 2px 2px 2px;
  font-size: 12px;
  color: var(--globalFontColor);
}

.bubbleImage {
  display: block;
  margin-top: 2px;
  margin-bottom: 5px;
  height: 50px;
  width: 50px;
  margin-left: auto;
  margin-right: auto;
}

/*Gpt3Message*/

.Gpt3Message {
  font-size: 60%;
  color: dimgray;
  text-align: center;
  padding-top: 4px;
  padding-left: 0px;
  padding-right: 0px;
}
