/* Header.css */
/* TK 03/28/2k22 */

/* Packages, dependencies, and shared libraries */
@import "../../globals.css";

/*************************/
/* Plant Bio Page Header */
/*************************/

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  background-color: var(--globalBackgroundColor);

  padding-left: max(5px, calc((100vw - 600px) / 2));
  padding-right: max(5px, calc((100vw - 600px) / 2));
}

.headerLogo {
  width: min(30%, 200px);
  margin: 10px;
  height: 30%;
  text-align: left;
}

.bioSocialMedias {
  display: flex;
  height: auto;
}

.socialMediaIcon {
  display: flex;
  width: min(40px, 7vw);
  height: min(40px, 7vw);
  margin-top: 10px;
  margin-right: 2vw;
}

.headerLogo:hover {
  cursor: pointer;
  opacity: 70%;
}

.socialMediaIcon:hover {
  cursor: pointer;
  opacity: 70%;
}
