/* Body.css */

.body {
  animation-name: fadeIn;
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  padding: 0px 10px 5px 10px;
  border-color: var(--globalFontColor);
  border-width: 0px;
  border-style: solid;
  border-radius: 10px;
  background-color: var(--bodyBackgroundColor);
  text-align: left;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.sayHelloTo {
  font-family: "Pacifico", cursive;
  font-weight: 400;
  padding-left: 20px;
  padding-top: 10px;
  margin-bottom: -4px;
  color: white; /*rgb(225, 251, 229);*/
  font-size: 21px;
  text-shadow: -0.5px 0.5px 0 #000, 0.5px 0.5px 0 #000, 0.5px -0.5px 0 #000,
    -0.5px -0.5px 0 #000;
  z-index: 5;
}

/***************************/
/* Name and Species Classes */
/***************************/

.name {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: left;
  justify-content: space-between;
  font-size: 40px;
  /*color: var(--globalFontColor);*/
  color: green;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 5px;
  font-family: var(--globalFont);
}

.name b {
  text-shadow: -0.5px 0.5px 0 #fff, 0.5px 0.5px 0 #fff, 0.5px -0.5px 0 #fff,
    -0.5px -0.5px 0 #fff;
}

.speciesNickname {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(6px + 2vmin);
  color: dimgray;
  font-style: italic;
  margin-top: 0px;
  padding-left: 5px;
  /*margin-bottom: 5px;*/
  /*padding-left: 20px;*/
}

.contentSection {
  background-color: var(--globalAccentColor);
  border-color: var(--globalFontColor);
  border-width: 0px;
  border-style: solid;
  border-radius: 5px;
}

.spriteAndBubbles {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
