/* home.css */
/* TK 03/30/2k22 */

/* Packages, dependencies, and shared libraries */
@import "../globals.css";

/***************************/
/* Home Page Classes */
/***************************/

div.homePageHeader {
  display: grid;
  grid-template-columns: 25% 50% 25%;
}

/* Page */

.homePage {
  /*background-color: var(--globalBackgroundColor);*/
  height: 85vh;
  padding: 5px;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nameSpeciesAndBubbles {
  position: absolute;
  top: 0px;
  bottom: 20px;
  left: 5px;
  display: flex;
  margin: 10px 0px 10px 5px;
  width: 90%;
  font-family: var(--globalFont);

  flex-direction: column;
  justify-content: space-between;
}

.nameArea {
  /*position: absolute;*/
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-between;
  margin-bottom: -5px;
  padding-left: 10px;
}

.bubbleArea {
  padding-top: 25px;
  padding-left: 10px;
}

.homePageName {
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: space-between;

  font-size: calc(25px + 2vmin);
  /*color: var(--globalFontColor);*/
  color: green;
  margin-top: 5px;
  font-family: var(--globalFont);
  border-radius: 5px;
  margin-bottom: -8px;
}

.homePageBody {
  animation-name: fadeIn;
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  border-color: var(--globalFontColor);
  border-width: 0px;
  border-style: solid;

  margin-left: 5px;
  margin-right: 5px;

  text-align: center;
}

div.dropdownMenu {
  justify-self: left;
}

.randomPlantContainer {
  position: relative;
  text-align: center;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  /*padding: 0px 0px 0px 15px;*/
  border-radius: 10px;
  background-color: var(--bodyBackgroundColor);
  /*width: 350px;*/
  /*background-color: var(--globalAccentColor);*/
}

.in {
  animation-name: fadeIn;
  opacity: 1;
}

.out {
  animation-name: fadeOut;
  opacity: 0;
}

.welcomeTextSection {
  background-color: var(--globalAccentColor);
  border-color: var(--globalFontColor);
  border-width: 0px;
  border-style: solid;
  border-radius: 5px;
  padding: 20px;
  padding-bottom: 10px;
  margin: 5px;
  margin-top: 15px;
}

h1.welcomeTextHeader {
  color: var(--globalFontColor);
  text-align: center;
  font-size: 25px;
}

p.welcomeTextBody {
  color: var(--globalFontColor);
  text-align: left;
}

button.goToPlantButton {
  justify-content: center;
  text-align: center;
  background-color: forestgreen;
  opacity: 100%;
  color: white;
  width: 90px;
  height: 40px;
  border-radius: 5px;
  border: 0px;
  font-size: 13px;
  font-family: var(--globalFont);
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.goToPlantButton:hover {
  opacity: 70%;
}

div.spriteAndButtonContainer {
  position: relative;
  padding: 20px -50px 10px 40px;
  font-family: var(--globalFont);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-top: 5px;
}

div.bioBubble {
  margin: 1vw 1.5vw 1vw 1.5vw;

  border-radius: 10px;
  border-style: solid;
  border-width: 0px;
  border-color: var(--globalFontColor);
  font-size: 13px;
  color: dimgray;
  background-color: var(--globalAccentColor2);
  display: inline-block;
  vertical-align: text-bottom;
  text-align: center;
  padding-top: 3px;
  padding-bottom: 5px;
}

div.bubbleText {
  padding: 7px 2px 2px 2px;
  font-size: 12px;
  color: var(--globalFontColor);
}

img.bubbleImage {
  margin-top: 2px;
  margin-bottom: 5px;
  height: 50px;
}

.contactUs {
  font-family: var(--globalFont);
  margin-top: 20px;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.contactUs:hover {
  opacity: 70%;
  cursor: pointer;
}

.line {
  display: block;
  border-bottom: 3px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-color: black;
  border-style: solid;
  width: 300px; /*calc(50vw - 10px);*/
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20px;
}

/*button.randomPlantButton {
  justify-content: center;
  text-align: center;
  background-color: forestgreen;
  opacity: 100%;
  color: white;
  width: 150px;
  height: 40px;
  border-radius: 5px;
  font-family: var(--globalFont);
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.randomPlantButton:hover {
  opacity: 70%;
}*/
